import React from 'react'
import Title from 'elements/Typography/Title'
import Emphasis from 'elements/Typography/Emphasis'
import DetailList from 'elements/Typography/DetailList'
import Header from 'elements/Typography/Header'
import {
  Text
} from '@chakra-ui/core'

export default function AccountSettingsPage () {
  return (
    <>
      <Title>Account overview</Title>
      <Header>Current plan</Header>
      <Text>You are currently on the <Emphasis>Sandbox Plan</Emphasis></Text>
      <Header>Plan usage for current period</Header>
      <DetailList w={200} items={{ runtime: 12, requests: 45, storage: 23 }}/>
    </>
  )
}
