import React from 'react'
import PropTypes from 'prop-types'
import { Text } from '@chakra-ui/core'

function Emphasis ({ children }) {
  return (
    <Text as="em" fontWeight="700">
      {children}
    </Text>
  )
}

Emphasis.propTypes = {
  children: PropTypes.node.isRequired
}

export default Emphasis
