import React, { useState } from 'react'
import Title from 'elements/Typography/Title'
import ConfirmEmailForm from 'views/Settings/ConfirmEmailForm'
import UpdateEmailForm from 'views/Settings/UpdateEmailForm'
import {
  useToast,
  Text
} from '@chakra-ui/core'
import Auth from '@aws-amplify/auth'

export default function UpdateEmailPage () {
  const [requestData, setRequestData] = useState(false)
  const toast = useToast()

  const notice = {
    title: 'Ooops!',
    description: 'There was a problem - please reach out to support',
    status: 'error',
    isClosable: true
  }

  const onReset = async ({ code }) => {
    const { email } = requestData
    try {
      await Auth.verifyCurrentUserAttributeSubmit('email', code)
    } catch (e) {
      toast({ ...notice, description: e.message })
    }
  }

  const onRequest = async ({ email }) => {
    try {
      const user = await Auth.currentAuthenticatedUser()
      await Auth.updateUserAttributes(user, { email })

      setRequestData({ email })
    } catch (e) {
      toast({ ...notice, description: e.message })
    }
  }
  return (
    <>

      <Title>Update email</Title>

      {requestData
        ? <Text>
            We&apos;ve sent a code to {requestData.email}. Please enter it here.
        </Text>
        : <Text>
            Use the form below to change your email address. We will send
            you a confirmation code to enter in the next step.
        </Text>
      }
      {requestData
        ? <ConfirmEmailForm onSubmit={onReset}/>
        : <UpdateEmailForm onSubmit={onRequest}/> }

    </>
  )
}
