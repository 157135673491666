import React from 'react'
import PropTypes from 'prop-types'
import { AuthConsumer } from 'contexts/AuthContext'
import { navigate } from 'gatsby'
import { LOGIN_ROUTE } from 'config/routes'

const PrivateRoute = ({ component: Component, location, ...rest }) => (
  <AuthConsumer>
    {({ isAuthenticated }) => {
      if (!isAuthenticated && location.pathname !== LOGIN_ROUTE) {
        // If we’re not logged in, redirect to the home page.
        navigate(LOGIN_ROUTE)
        return null
      }

      return <Component {...rest} />
    }}
  </AuthConsumer>
)

PrivateRoute.propTypes = {
  component: PropTypes.any.isRequired,
  location: PropTypes.any
}

export default PrivateRoute
