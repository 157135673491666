import React from 'react'
import { Router } from '@reach/router'
import SettingsFrame from 'elements/Frame/SettingsFrame'
// import Layout from 'elements/Frame/Layout'
import PrivateRoute from 'elements/Routing/PrivateRoute'
import AccountOverviewPage from 'views/Settings/AccountOverviewPage'
import UpdatePasswordPage from 'views/Settings/UpdatePasswordPage'
import UpdateEmailPage from 'views/Settings/UpdateEmailPage'
// import ProfilePage from 'views/SettingsProfilePage'
// import ParameterListPage from 'views/Parameter/ParameterListPage'
// import ParameterCreatePage from 'views/Parameter/ParameterCreatePage'
// import ParameterUpdatePage from 'views/Parameter/ParameterUpdatePage'
import { UPDATE_PASSWORD_ROUTE, UPDATE_EMAIL_ROUTE, ACCOUNT_OVERVIEW_ROUTE } from 'config/routes'

function SettingsRouteContainer (props) {
  return (
    <SettingsFrame>
      <Router primary={false}>
        <PrivateRoute
          location
          path={ACCOUNT_OVERVIEW_ROUTE}
          component={AccountOverviewPage}
        />

        <PrivateRoute
          location
          path={UPDATE_EMAIL_ROUTE}
          component={UpdateEmailPage}
        />

        <PrivateRoute
          location
          path={UPDATE_PASSWORD_ROUTE}
          component={UpdatePasswordPage}
        />
        {/*
        <PrivateRoute
          location
          path={PARAMETER_LIST_ROUTE}
          component={ParameterListPage}
        />
        <PrivateRoute
          location
          path={PARAMETER_CREATE_ROUTE}
          component={ParameterCreatePage}
        />
        <PrivateRoute
          location
          path={PARAMETER_UPDATE_ROUTE}
          component={ParameterUpdatePage}
        /> */}
      </Router>
    </SettingsFrame>
  )
}

SettingsRouteContainer.propTypes = {

}

export default SettingsRouteContainer
