import React, { useState } from 'react'
import Title from 'elements/Typography/Title'
import UpdatePasswordForm from 'views/Settings/UpdatePasswordForm'
import {
  useToast,
  Text
} from '@chakra-ui/core'
import Auth from '@aws-amplify/auth'

export default function UpdatePasswordPage () {
  const [isChanged, setChanged] = useState(false)
  const toast = useToast()

  const notice = {
    title: 'Ooops!',
    description: 'There was a problem - please reach out to support',
    status: 'error',
    isClosable: true
  }

  const onSubmit = async ({ current, updated }) => {
    try {
      const currentUser = await Auth.currentAuthenticatedUser()
      await Auth.changePassword(currentUser, current, updated)
      setChanged(true)
    } catch (e) {
      toast({ ...notice, description: e.message })
    }
  }
  return (
    <>

      <Title>Change password</Title>

      {isChanged ? (
        <Text>Password changed!</Text>
      ) : (
        <Text>Enter your existing and your new password below.</Text>
      )}
      <UpdatePasswordForm onSubmit={onSubmit}/>

    </>
  )
}
