import React from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import Form from 'elements/Form/Form'
import SubmitButton from 'elements/Button/SubmitButton'
import {
  FormErrorMessage,
  FormControl,
  InputGroup,
  Icon,
  Stack,
  InputLeftElement,
  Input
} from '@chakra-ui/core'

export default function ConfirmEmailForm ({ onSubmit }) {
  const { handleSubmit, errors, register, formState } = useForm()

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={5} mt={5} bg="gray.50" maxWidth={600} borderRadius={8} p={10}>
        <FormControl isInvalid={errors.email}>
          <InputGroup>
            <InputLeftElement><Icon name="email" color="gray.300" /></InputLeftElement>
            <Input
              name="email"
              placeholder="your email"
              ref={register({ required: 'Field is required' })}
            />
          </InputGroup>
          <FormErrorMessage>
            {errors.email && errors.email.message}
          </FormErrorMessage>
        </FormControl>

        <SubmitButton
          isLoading={formState.isSubmitting}
        >Submit</SubmitButton>
      </Stack>
    </Form>
  )
}
ConfirmEmailForm.propTypes = {
  onSubmit: PropTypes.func.isRequired
}
